import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { DeviceId, DeviceMS } from '../../../site-detail/models/Device';
import { DeviceStateUpdateExecutionInfo } from '../../../site-detail/models/Execution';
import { Site, SiteId } from '../../../site-detail/models/Site';
import { UiProfile } from '../../../site-detail/models/UiProfile';
import { DeviceService } from '../../../site-detail/services/device.service';
import { DeviceActions } from '../../../site-detail/store/actions/device-detail';
import { SiteDevicesActions } from '../../../site-detail/store/actions/devices';
import { updateDeviceDefinitionUI } from '../../../site-detail/store/actions/ui';
import { getSelectedSiteDevices } from '../../../site-detail/store/selectors/devices';
import { getUiDeviceDefinitions, getUIDevicesStatesSync } from '../../../site-detail/store/selectors/ui';
import { getUserSiteName } from '../../../site-detail/utils/owner.util';
import { getSites } from '../../../site-list/store/selectors/site-list';
import { ToasterDeviceUpdateMessage, ToasterMessageType } from '../../models/Toaster';
import { UISocketDataMessage, UISocketDeviceStateUpdateState } from '../../models/UISocket';
import { addToasterMessage } from '../../store/actions/addToasterMessage';
import { AppState } from '../../store/app-state';
import { SocketAction, SocketActionHandler } from '../SocketActionHandler';

export interface UISocketDeviceStateUpdateMessage extends UISocketDataMessage {
    deviceId: DeviceId;
    siteId: SiteId;
    states: UISocketDeviceStateUpdateState[];
}

@Injectable()
export class DeviceStateUpdatedHandler implements SocketActionHandler {
    readonly action: SocketAction = 'device_state_update';

    constructor(
        private deviceService: DeviceService,
        private store: Store<AppState>,
    ) {}

    handle(message: UISocketDeviceStateUpdateMessage): void {
        message.states.forEach((state) =>
            this.store.dispatch(
                DeviceActions.deviceUpdateCompleted({
                    deviceId: message.deviceId,
                    value: state.value,
                    stateId: state.name,
                }),
            ),
        );

        this.updateSiteDevice(message.siteId, message.deviceId);

        combineLatest([
            this.store.select(getSites),
            this.store.select(getSelectedSiteDevices),
            this.store.select(getUiDeviceDefinitions),
            this.store.select(getUIDevicesStatesSync),
        ])
            .pipe(take(1))
            .subscribe(([sites, devices, uiDeviceDefinitions, devicesStatesSync]) => {
                const site: Site = sites[message.siteId];
                if (!site) {
                    return;
                }

                const device: DeviceMS = devices.find((d) => d.id === message.deviceId);
                if (!device) {
                    return;
                }

                if (device.uiType in uiDeviceDefinitions) {
                    this.sendToasterDeviceUpdateStateMessage(
                        site,
                        device,
                        uiDeviceDefinitions[device.uiType],
                        devicesStatesSync,
                        message,
                    );
                } else {
                    this.deviceService.getDeviceUI(device.uiType).subscribe((definition) => {
                        this.sendToasterDeviceUpdateStateMessage(site, device, definition, devicesStatesSync, message);
                        this.store.dispatch(
                            updateDeviceDefinitionUI({
                                deviceType: device.uiType,
                                definitionUI: definition,
                            }),
                        );
                    });
                }
            });
    }

    private updateSiteDevice(id_site: string, id_device: string) {
        // TODO needs refacto; reload full device to pass through value conversion/transform/... defined in
        //  DeviceDetailComponent:updateDeviceDefinition(), so value is correctly store in store;
        //  refacto must go further by rethink and normalize store AND move all business conversion in
        //  MS Device ; transforms for humans reading purpose are only behaviors that must be kept here
        this.deviceService.getDeviceById(id_device).subscribe((newDevice) => {
            this.store.dispatch(SiteDevicesActions.deviceUpdated({ siteId: id_site, device: newDevice }));
        });
    }

    private sendToasterDeviceUpdateStateMessage(
        site: Site,
        device: DeviceMS,
        deviceDefinitionUI: UiProfile,
        devicesStatesSync: DeviceStateUpdateExecutionInfo[],
        message: UISocketDeviceStateUpdateMessage,
    ) {
        message.states
            .filter((state) => devicesStatesSync.some((job) => job.state === state.name))
            .forEach((state: UISocketDeviceStateUpdateState) => {
                const deviceUpdateMessage: ToasterDeviceUpdateMessage = {
                    type: ToasterMessageType.DEVICE_UPDATE_STATE,
                    id: crypto.randomUUID(),
                    content: {
                        siteId: site.id,
                        deviceId: message.deviceId,
                        deviceName: device['name'],
                        isUpdateValid: true,
                        stateLabel: 'unknown',
                        userSiteName: getUserSiteName(site.owner),
                        newValue: state.value,
                    },
                };

                // Get stateLabel from ui definition
                const uiProperty = deviceDefinitionUI.properties[state.name];
                if (uiProperty) {
                    deviceUpdateMessage.content.stateLabel = uiProperty.label;
                    this.store.dispatch(addToasterMessage({ message: deviceUpdateMessage }));
                }
            });
    }
}
