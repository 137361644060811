import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { catchError } from 'rxjs/operators';
import { HttpService } from '../../shared/services/http.service';
import { DeviceExecutionCommand, DeviceId, DeviceMS, DevicePairingRequest } from '../models/Device';

@Injectable()
export class DeviceService {
    constructor(
        private httpService: HttpService,
        private http: HttpClient,
    ) {}

    getDeviceUI(deviceName: string): Observable<any> {
        return this.http.get(`./assets/definitionsUI/${deviceName}.json`).pipe(
            catchError(() => {
                return of([]);
            }),
        );
    }

    getSiteDevices(idSite: string, withSynchro = false): Observable<DeviceMS[]> {
        return this.httpService.get(`/site/${idSite}/devices?synch=${withSynchro}`);
    }

    getDeviceById(idDevice: string): Observable<DeviceMS> {
        return this.httpService.get('/device/' + idDevice);
    }

    pairGatewayDevices(req: DevicePairingRequest): Observable<any> {
        return this.httpService.patch('/device/pairRemote', req);
    }

    executeCommand(deviceId: DeviceId, bodyReq: DeviceExecutionCommand): Observable<any> {
        return this.httpService.patch(`/device/${deviceId}/exec`, {
            ...bodyReq,
        });
    }
}
