import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Socket } from 'socket.io-client';
import { DeviceStateUpdateExecutionInfo, ExecutionInfo } from '../../site-detail/models/Execution';
import { getUIDevicesSectionsSync, getUIDevicesStatesSync } from '../../site-detail/store/selectors/ui';
import { WEBSOCKET } from '../injectionTokens';
import {
    Actions,
    Event,
    SetExecutionsMessage,
    SetUserIdMessage,
    UISocketDataMessage,
    UISocketTextMessage,
} from '../models/UISocket';
import { User } from '../models/User';
import { AppState } from '../store/app-state';
import { getUserLoginState } from '../store/selectors/login';
import { SocketActionHandler, socketActionHandlersToken } from './SocketActionHandler';

@Injectable()
export class UISocketService {
    readonly userLogin$: Observable<User>;
    userLogin: User = null;

    readonly devicesStatesSync$: Observable<Array<DeviceStateUpdateExecutionInfo>>;
    devicesStatesSync: Array<DeviceStateUpdateExecutionInfo> = null;

    readonly devicesSectionsSync$: Observable<Array<ExecutionInfo>>;
    devicesSectionsSync: Array<ExecutionInfo> = null;

    public constructor(
        @Inject(WEBSOCKET) private socket: Socket,
        @Inject(socketActionHandlersToken) private handlers: SocketActionHandler[],
        private store: Store<AppState>,
    ) {
        this.userLogin$ = this.store.select(getUserLoginState);
        this.devicesStatesSync$ = this.store.select(getUIDevicesStatesSync);
        this.devicesSectionsSync$ = this.store.select(getUIDevicesSectionsSync);
    }

    public initSocket(): void {
        this.socket.on(Event.CONNECT, () => {
            // eslint-disable-next-line no-console
            console.info('Connection established with server');

            if (this.userLogin?.id?.length) {
                this.setSessionUserId(this.userLogin.id);
            }

            if (this.devicesStatesSync?.length) {
                this.setExecutions(this.devicesStatesSync.map((el) => el.job_id));
            }

            if (this.devicesSectionsSync?.length) {
                this.setExecutions(this.devicesSectionsSync.map((el) => el.job_id));
            }
        });

        this.socket.on(Event.DISCONNECT, () => {
            // eslint-disable-next-line no-console
            console.info('Disconnected from server');
        });

        // Message Handlers
        this.socket.on('message', (message: UISocketTextMessage) => {
            // eslint-disable-next-line no-console
            console.info('Message received from server', message);
        });

        this.handlers.forEach((handler: SocketActionHandler) => {
            this.socket.on(handler.action, (message: UISocketDataMessage) => {
                handler.handle(message);
            });
        });

        this.userLogin$.subscribe((userLogin) => {
            if (userLogin?.id?.length) {
                this.userLogin = userLogin;
                this.setSessionUserId(this.userLogin.id);
            }
        });

        this.devicesStatesSync$.subscribe((devicesStatesSync) => {
            this.devicesStatesSync = devicesStatesSync;
        });

        this.devicesSectionsSync$.subscribe((devicesSectionsSync) => {
            this.devicesSectionsSync = devicesSectionsSync;
        });
    }

    public setSessionUserId(userId: string): void {
        const sessionIdMessage: SetUserIdMessage = {
            userId: userId,
        };
        this.socket.emit(Actions.SET_USER_ID, sessionIdMessage);
    }

    public setExecutions(executions: string[]): void {
        const sessionSitesMessage: SetExecutionsMessage = {
            executions,
        };
        this.socket.emit(Actions.SET_EXECUTIONS, sessionSitesMessage);
    }
}
