import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { VIDEO_CALL_STATUS } from '../../../video-conference/models/video-call';
import { VIDEO_CALL_EVENT_TYPE } from '../../../video-conference/models/video-call-event';
import { VideoCallProvider } from '../../../video-conference/services/VideoCallProvider';
import { VideoCallsActions } from '../../../video-conference/store/actions/videoCall';
import { ToasterJoinVideoCallMessage, ToasterMessageType } from '../../models/Toaster';
import { UISocketDataMessage } from '../../models/UISocket';
import { VideoCallEventBase } from '../../models/VideoCallEventBase';
import { addToasterMessage } from '../../store/actions/addToasterMessage';
import { AppState } from '../../store/app-state';
import { SocketAction, SocketActionHandler } from '../SocketActionHandler';

interface UISocketJoinedParticipantVideoCallEvent extends VideoCallEventBase {
    type: VIDEO_CALL_EVENT_TYPE.JOINED_PARTICIPANT;
    userName: string;
    sessionId: string;
    url: string;
    ownerToken: string;
    isOwner: boolean;
}

export interface UISocketJoinedParticipantVideoCallEventMessage extends UISocketDataMessage {
    videoCallEvent: UISocketJoinedParticipantVideoCallEvent;
}

@Injectable()
export class VideoCallJoinedParticipantHandler implements SocketActionHandler {
    readonly action: SocketAction = 'joined_participant';

    constructor(
        @Inject('VideoCallProvider') private videoCallProvider: VideoCallProvider,
        private store: Store<AppState>,
    ) {}

    handle({ videoCallEvent: event }: UISocketJoinedParticipantVideoCallEventMessage): void {
        this.store.dispatch(
            VideoCallsActions.videoCallStatusUpdated({
                siteId: event.siteId,
                status: event.isOwner ? VIDEO_CALL_STATUS.IN_PROGRESS : VIDEO_CALL_STATUS.AVAILABLE,
            }),
        );
        if (event.isOwner) {
            return;
        }

        const installerHasVideoCallInstance = this.videoCallProvider.hasVideoCallInstance();
        if (installerHasVideoCallInstance) {
            return;
        }

        const joinVideoCallMessage: ToasterJoinVideoCallMessage = {
            id: crypto.randomUUID(),
            content: {
                siteId: event.siteId,
                url: event.url,
                ownerToken: event.ownerToken,
                userName: event.userName,
            },
            type: ToasterMessageType.GUEST_JOINED_VIDEO_CALL,
        };

        this.store.dispatch(
            addToasterMessage({
                message: joinVideoCallMessage,
            }),
        );
    }
}
