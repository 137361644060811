import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import type { InterventionCreateRequest } from '../../../interventions/models/InterventionCreateRequest';
import { PendingInterventionCreateRequest } from '../../../interventions/models/PendingInterventionCreateRequest';
import { InterventionService } from '../../../interventions/services/intervention.service';
import { InterventionActions } from '../../../interventions/store/actions/interventions';
import { DeviceMS, FailureType } from '../../../site-detail/models/Device';
import {
    DeviceStateUpdateExecutionInfo,
    Execution,
    ExecutionInfo,
    ExecutionStatus,
} from '../../../site-detail/models/Execution';
import { Site } from '../../../site-detail/models/Site';
import { UiProfile } from '../../../site-detail/models/UiProfile';
import { DeviceService } from '../../../site-detail/services/device.service';
import { SiteDevicesActions } from '../../../site-detail/store/actions/devices';
import {
    removePendingInterventionAction,
    updateDeviceStateToSyncExecutionStatusById,
    updateExecutionStatus,
    updateManualExecution,
} from '../../../site-detail/store/actions/ui';
import { UIDeviceDefinitionState } from '../../../site-detail/store/reducers/ui.devicedefinitions';
import { getSelectedSiteDevices } from '../../../site-detail/store/selectors/devices';
import {
    getUiDeviceDefinitions,
    getUIDevicesSectionsSync,
    getUIDevicesStatesSync,
    getUIManualExecutions,
    getUIPendingInterventionActions,
} from '../../../site-detail/store/selectors/ui';
import { getUserSiteName } from '../../../site-detail/utils/owner.util';
import { getSites } from '../../../site-list/store/selectors/site-list';
import {
    ToasterDeviceStateRefreshFailedMessage,
    ToasterDeviceUpdateMessage,
    ToasterMessageType,
} from '../../models/Toaster';
import { UISocketDataMessage } from '../../models/UISocket';
import { AppState } from '../../store/app-state';
import { ErrorService } from '../error.service';
import { SocketAction, SocketActionHandler } from '../SocketActionHandler';

interface UISocketExecutionUpdateMessage extends UISocketDataMessage {
    job_id: string;
    status: ExecutionStatus;
    failureType?: string;
}

@Injectable()
export class ExecutionStatusUpdatedHandler implements SocketActionHandler {
    readonly action: SocketAction = 'execution_status_update';

    constructor(
        private deviceService: DeviceService,
        private interventionService: InterventionService,
        private errorService: ErrorService,
        private store: Store<AppState>,
    ) {}

    handle(message: UISocketExecutionUpdateMessage): void {
        combineLatest([
            this.store.select(getUIDevicesSectionsSync),
            this.store.select(getUIDevicesStatesSync),
            this.store.select(getUIManualExecutions),
            this.store.select(getSites),
            this.store.select(getSelectedSiteDevices),
            this.store.select(getUiDeviceDefinitions),
            this.store.select(getUIPendingInterventionActions),
        ])
            .pipe(take(1))
            .subscribe(
                ([
                    devicesSectionsSync,
                    devicesStatesSync,
                    manualExecutions,
                    sites,
                    devices,
                    uiDeviceDefinitions,
                    pendingInterventionActions,
                ]) => {
                    // Look for execution in list of device section sync
                    const executionsInfoForSectionRefresh = devicesSectionsSync.filter(
                        (exec) => exec.job_id === message.job_id,
                    );

                    // Look for execution in list of device state sync
                    const deviceStateUpdateExecution = devicesStatesSync.find((exec) => exec.job_id === message.job_id);

                    const manualExecutionExec = manualExecutions.find((exec) => exec.job_id === message.job_id);

                    if (executionsInfoForSectionRefresh && executionsInfoForSectionRefresh.length > 0) {
                        this.store.dispatch(
                            updateExecutionStatus({
                                execution: {
                                    status: message.status,
                                    job_id: message.job_id,
                                },
                            }),
                        );

                        if (message.status === ExecutionStatus.QUEUED_GATEWAY_SIDE) {
                            // put a 2 min timer
                            setTimeout(() => {
                                const executionsStandByInfoForSectionRefresh = devicesSectionsSync.filter(
                                    (exec) => exec.job_id === message.job_id,
                                );
                                if (
                                    executionsStandByInfoForSectionRefresh &&
                                    executionsStandByInfoForSectionRefresh.length > 0
                                ) {
                                    this.store.dispatch(
                                        updateExecutionStatus({
                                            execution: {
                                                status: ExecutionStatus.FAILED,
                                                job_id: message.job_id,
                                            },
                                        }),
                                    );
                                    executionsStandByInfoForSectionRefresh.map(
                                        (executionStandByInfoForSectionRefresh: ExecutionInfo) => {
                                            this.verifyDefinitionAndSendStateStateTaosterMessage(
                                                sites,
                                                devices,
                                                uiDeviceDefinitions,
                                                executionStandByInfoForSectionRefresh,
                                                true,
                                            );
                                        },
                                    );
                                }
                            }, 120 * 1000);
                        } else if (
                            message.status === ExecutionStatus.FAILED &&
                            message.failureType !== FailureType.CMDDEPRECATED
                        ) {
                            // Format message for toaster
                            executionsInfoForSectionRefresh.map((executionInfoForSectionRefresh: ExecutionInfo) => {
                                this.verifyDefinitionAndSendStateStateTaosterMessage(
                                    sites,
                                    devices,
                                    uiDeviceDefinitions,
                                    executionInfoForSectionRefresh,
                                );
                            });
                        }
                    } else if (deviceStateUpdateExecution) {
                        const site: Site = sites[deviceStateUpdateExecution.siteId];
                        if (!site) {
                            return;
                        }
                        const device: DeviceMS = devices.find((d) => d.id === deviceStateUpdateExecution.deviceId);
                        if (!device) {
                            return;
                        }
                        if (message.status === ExecutionStatus.QUEUED_GATEWAY_SIDE) {
                            // put a 2 min timer
                            setTimeout(() => {
                                const deviceStandByExecution = devicesStatesSync.find(
                                    (exec) => exec.job_id === message.job_id,
                                );
                                if (deviceStandByExecution) {
                                    this.store.dispatch(
                                        updateDeviceStateToSyncExecutionStatusById({
                                            execution: {
                                                status: ExecutionStatus.FAILED,
                                                job_id: message.job_id,
                                            },
                                        }),
                                    );
                                    this.saveCompletedDeviceAction(pendingInterventionActions, message, true);
                                    this.verifyDefinitionAndSendStateUpdateTaosterMessage(
                                        sites,
                                        devices,
                                        uiDeviceDefinitions,
                                        deviceStateUpdateExecution,
                                        true,
                                    );
                                }
                            }, 120 * 1000);
                        } else if (
                            message.status === ExecutionStatus.FAILED &&
                            message.failureType !== FailureType.CMDDEPRECATED
                        ) {
                            this.verifyDefinitionAndSendStateUpdateTaosterMessage(
                                sites,
                                devices,
                                uiDeviceDefinitions,
                                deviceStateUpdateExecution,
                            );
                        } else if (message.status === ExecutionStatus.COMPLETED) {
                            this.saveCompletedDeviceAction(pendingInterventionActions, message);
                        }
                        this.store.dispatch(
                            updateDeviceStateToSyncExecutionStatusById({
                                execution: {
                                    status: message.status,
                                    job_id: message.job_id,
                                },
                            }),
                        );
                    } else if (manualExecutionExec) {
                        const executionUpdated: Execution = {
                            job_id: manualExecutionExec.job_id,
                            deviceId: manualExecutionExec.deviceId,
                            state: manualExecutionExec.state,
                            // we update status with the new one
                            status: message.status,
                            failureType:
                                message.failureType !== FailureType.NO_FAILURE ? message.failureType : undefined,
                        };
                        this.store.dispatch(updateManualExecution({ exec: executionUpdated }));
                    }
                },
            );
    }

    private verifyDefinitionAndSendStateStateTaosterMessage(
        sites: Record<string, Site>,
        devices: DeviceMS[],
        uiDeviceDefinitions: UIDeviceDefinitionState,
        executionInfo: ExecutionInfo,
        isQueued = false,
    ) {
        const site: Site = sites[executionInfo.siteId];
        if (!site) {
            return;
        }
        const device: DeviceMS = devices.find((d) => d.id === executionInfo.deviceId);
        if (!device) {
            return;
        }
        if (device.type in uiDeviceDefinitions) {
            this.sendDeviceStateRefreshFailedOrQueuedMessage(
                site,
                device,
                uiDeviceDefinitions[device.type],
                executionInfo,
                isQueued,
            );
        } else {
            // Get device definition from rest api
            this.deviceService.getDeviceUI(device.uiType).subscribe((definition) => {
                this.sendDeviceStateRefreshFailedOrQueuedMessage(site, device, definition, executionInfo, isQueued);
            });
        }
    }

    private verifyDefinitionAndSendStateUpdateTaosterMessage(
        sites: Record<string, Site>,
        devices: DeviceMS[],
        uiDeviceDefinitions: UIDeviceDefinitionState,
        executionInfo: DeviceStateUpdateExecutionInfo,
        isQueued = false,
    ) {
        const site: Site = sites[executionInfo.siteId];
        if (!site) {
            return;
        }
        const device: DeviceMS = devices.find((d) => d.id === executionInfo.deviceId);
        if (!device) {
            return;
        }
        if (device.type in uiDeviceDefinitions) {
            this.sendDeviceStateUpdateFailedOrQueuedMessage(
                site,
                device,
                uiDeviceDefinitions[device.type],
                executionInfo,
                isQueued,
            );
        } else {
            // Get device definition from rest api
            this.deviceService.getDeviceUI(device.uiType).subscribe((definition) => {
                this.sendDeviceStateUpdateFailedOrQueuedMessage(site, device, definition, executionInfo, isQueued);
            });
        }
    }

    private sendDeviceStateUpdateFailedOrQueuedMessage(
        site: Site,
        device: DeviceMS,
        deviceDefinitionUI: UiProfile,
        executionInfo: DeviceStateUpdateExecutionInfo,
        queued = false,
    ) {
        const deviceUpdateMessage: ToasterDeviceUpdateMessage = {
            type: ToasterMessageType.DEVICE_UPDATE_STATE,
            id: crypto.randomUUID(),
            content: {
                siteId: site.id,
                deviceId: device.id,
                deviceName: device['name'],
                isUpdateValid: queued,
                isQueued: queued,
                stateLabel: 'unknown',
                userSiteName: getUserSiteName(site.owner),
            },
        };

        // Get stateLabel from ui definition
        const uiProperty = deviceDefinitionUI.properties[executionInfo.state];
        if (uiProperty) {
            deviceUpdateMessage.content.stateLabel = uiProperty.label;
        }

        this.errorService.showToasterError(deviceUpdateMessage, {
            duration: 45 * 1000, // how long do we want to display the error toaster
        });
    }

    private sendDeviceStateRefreshFailedOrQueuedMessage(
        site: Site,
        device: DeviceMS,
        deviceDefinitionUI: UiProfile,
        executionInfo: ExecutionInfo,
        queued = false,
    ) {
        const content = {
            siteId: site.id,
            deviceId: device.id,
            deviceName: device['name'],
            stateLabel: 'unknown',
            isQueued: queued,
            userSiteName: getUserSiteName(site.owner),
        };
        const deviceStateRefreshFailedMessage: ToasterDeviceStateRefreshFailedMessage = {
            type: ToasterMessageType.DEVICE_REFRESH_STATE_FAILED,
            id: crypto.randomUUID(),
            content,
        };

        // Get stateLabel from ui definition

        // Get stateLabel from ui definition
        const uiProperty = deviceDefinitionUI.properties[executionInfo.state];
        if (uiProperty) {
            deviceStateRefreshFailedMessage.content.stateLabel = uiProperty.label;
        }

        this.errorService.showToasterError(deviceStateRefreshFailedMessage, {
            duration: 15 * 1000, // how long do we want to display the error toaster
        });
        Sentry.captureMessage('device_refresh_state_failed', {
            extra: content,
        });
    }

    private saveCompletedDeviceAction(
        pendingInterventionActions: PendingInterventionCreateRequest[],
        message: UISocketExecutionUpdateMessage,
        queued = false,
    ) {
        const pendingAction = pendingInterventionActions.find((a) => a.job_id === message.job_id);
        const newIntervention: InterventionCreateRequest = pendingAction;
        if (queued) {
            newIntervention.data.queued = true;
        }
        this.interventionService.createInterventionEvent(newIntervention).subscribe((updatedIntervention) => {
            this.store.dispatch(
                removePendingInterventionAction({
                    interventionAction: pendingAction,
                }),
            );
            if (!queued) {
                this.updateSiteDevice(pendingAction.id_site, pendingAction.id_device);
            }
            this.store.dispatch(InterventionActions.updateSiteIntervention({ intervention: updatedIntervention }));
        });
    }

    private updateSiteDevice(id_site: string, id_device: string) {
        // TODO needs refacto; reload full device to pass through value conversion/transform/... defined in
        //  DeviceDetailComponent:updateDeviceDefinition(), so value is correctly store in store;
        //  refacto must go further by rethink and normalize store AND move all business conversion in
        //  MS Device ; transforms for humans reading purpose are only behaviors that must be kept here
        this.deviceService.getDeviceById(id_device).subscribe((newDevice) => {
            this.store.dispatch(SiteDevicesActions.deviceUpdated({ siteId: id_site, device: newDevice }));
        });
    }
}
