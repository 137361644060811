export enum Event {
    CONNECT = 'connect',
    DISCONNECT = 'disconnect',
}

export enum Actions {
    SET_USER_ID = 'set_user_id',
    SET_EXECUTIONS = 'set_executions',
}

export enum UISocketMessageType {
    TEXT = 'text',
    DATA = 'data',
}

export interface SetUserIdMessage {
    userId: string;
}

export interface UISocketTextMessage {
    type: UISocketMessageType.TEXT;
    message: unknown;
}

export interface UISocketDataMessage {
    type: UISocketMessageType.DATA;
}

export interface UISocketDeviceStateUpdateState {
    name: string;
    value: string;
}

export interface SetExecutionsMessage {
    executions: string[];
}
