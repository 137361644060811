import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { bootstrapApplication as bootstrapApplication_1, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { io } from 'socket.io-client';
import p from '../package.json';
import { BoxDiagService } from './app/admin/components/debug/box-diag.service';
import { DebugApi } from './app/admin/components/debug/debug-api.service';
import {
    AdminMaintenancePeriodApi,
} from './app/admin/components/maintenance-period/services/admin-maintenance-period-api.service';
import {
    AdminMaintenancePeriodService,
} from './app/admin/components/maintenance-period/services/admin-maintenance-period.service';
import adminMaintenancePeriodReducer, {
    ADMIN_MAINTENANCE_PERIOD_INITIAL_STATE,
} from './app/admin/components/maintenance-period/store/reducers';
import { AdminAuthGuard } from './app/admin/guards/auth.guard';
import { RoleGuardService } from './app/admin/guards/role.guard';
import { AdminAuthService } from './app/admin/services/auth.service';
import { BackOfficeService } from './app/admin/services/back.office.service';
import { CountriesService } from './app/admin/services/countries.service';
import { AdminHttpService } from './app/admin/services/http.service';
import { AdminSecurityService } from './app/admin/services/security.service';
import { AdminSharedService } from './app/admin/services/shared.service';
import { AdminApi } from './app/admin/store/api/admin';
import adminReducer, { ADMIN_INITIAL_STATE } from './app/admin/store/reducers/admin';
import { HttpAlertResolutionGateway } from './app/alert-resolution/gateways/HttpAlertResolutionGateway';
import { AlertResolutionEffects } from './app/alert-resolution/store/effects/alert-resolution';
import { AlertResolutionNavigationEffects } from './app/alert-resolution/store/effects/navigation';
import alertResolutionReducer, {
    ALERT_RESOLUTION_INITIAL_STATE,
} from './app/alert-resolution/store/reducers/alert-resolution';
import { HttpAlertMailingConfigGateway } from './app/alerts-notification-config/gateways/HttpAlertMailingConfigGateway';
import {
    HttpAlertNotificationConfigGateway,
} from './app/alerts-notification-config/gateways/HttpAlertNotificationConfigGateway';
import {
    AlertsNotificationConfigService,
} from './app/alerts-notification-config/services/alerts-notification-config.service';
import { AlertsMailingConfigEffects } from './app/alerts-notification-config/store/effects/alerts-mailing-config';
import {
    AlertsNotificationConfigEffects,
} from './app/alerts-notification-config/store/effects/alerts-notification-config';
import { AlertNotificationAnalyticsEffects } from './app/alerts-notification-config/store/effects/analytics';
import alertsNotificationReducer, {
    ALERTS_NOTIFICATION_INITIAL_STATE,
} from './app/alerts-notification-config/store/reducers/alerts-notification-config';
import { AlertService } from './app/alerts/services/alert.service';
import { AlertsEffects } from './app/alerts/store/effects/alerts';
import alertsReducer, { ALERTS_INITIAL_STATE } from './app/alerts/store/reducers/alerts';
import { AppComponent } from './app/app.component';
import { routing } from './app/app.routing';
import { AppConfig } from './app/AppConfig';
import { AuthService } from './app/core/services/auth.service';
import { SecurityService } from './app/core/services/security.service';
import { UserService } from './app/core/services/user.service';
import { UserLegalDocService } from './app/core/services/userLegalDoc.service';
import { LegalDocsApi } from './app/core/store/api/legalDocs';
import { LangEffects } from './app/core/store/effects/lang';
import legalDocReducer, { LEGAL_DOCUMENT_INITIAL_STATE } from './app/core/store/reducers/legalDocument';
import { InterventionService } from './app/interventions/services/intervention.service';
import { InterventionsApi } from './app/interventions/store/api/interventions';
import interventionsReducer, { INTERVENTIONS_INITIAL_STATE } from './app/interventions/store/reducers/interventions';
import { MaintenancePeriodService } from './app/maintenance-period/services/maintenancePeriod.service';
import { MaintenancePeriodsEffects } from './app/maintenance-period/store/effects/maintenancePeriods';
import maintenancePeriodReducer, {
    MAINTENANCE_PERIOD_INITIAL_STATE,
} from './app/maintenance-period/store/reducers/maintenancePeriod';
import { MessageService } from './app/messages/services/message.service';
import { MessagesApi } from './app/messages/store/api/messages';
import messageReducer, { MESSAGES_INITIAL_STATE } from './app/messages/store/reducers/messages';
import { HttpService as QueryHttpService } from './app/query/services/http.service';
import { QueryService } from './app/query/services/query.service';
import { AuthGuard } from './app/shared/guards/auth.guard';
import { createTranslateLoader } from './app/shared/i18n/createTranslateLoader';
import { APP_CONFIG, WEBSOCKET, WINDOW_OBJECT } from './app/shared/injectionTokens';
import { AnalyticsService } from './app/shared/services/analytics.service';
import { ErrorService } from './app/shared/services/error.service';
import { HttpService } from './app/shared/services/http.service';
import { LocalStorageService } from './app/shared/services/localStorage.service';
import { ModalService } from './app/shared/services/modal.service';
import { NavigationService } from './app/shared/services/NavigationService';
import { socketActionHandlersToken } from './app/shared/services/SocketActionHandler';
import { DeviceCreatedHandler } from './app/shared/services/socketActionHandlers/DeviceCreatedHandler';
import { DeviceLabelUpdatedHandler } from './app/shared/services/socketActionHandlers/DeviceLabelUpdatedHandler';
import { DeviceRemovedHandler } from './app/shared/services/socketActionHandlers/DeviceRemovedHandler';
import { DeviceStateUpdatedHandler } from './app/shared/services/socketActionHandlers/DeviceStateUpdatedHandler';
import {
    ExecutionStatusUpdatedHandler
} from './app/shared/services/socketActionHandlers/ExecutionStatusUpdatedHandler';
import { InterventionGrantedHandler } from './app/shared/services/socketActionHandlers/InterventionGrantedHandler';
import { NewInstallerMessageHandler } from './app/shared/services/socketActionHandlers/NewInstallerMessageHandler';
import { ServegoAccessGrantedHandler } from './app/shared/services/socketActionHandlers/ServegoAccessGrantedHandler';
import {
    VideoCallInvitationSentHandler
} from './app/shared/services/socketActionHandlers/VideoCallInvitationSentHandler';
import {
    VideoCallJoinedParticipantHandler
} from './app/shared/services/socketActionHandlers/VideoCallJoinedParticipantHandler';
import { VideoCallLeftHandler } from './app/shared/services/socketActionHandlers/VideoCallLeftHandler';
import {
    VideoCallLeftParticipantHandler
} from './app/shared/services/socketActionHandlers/VideoCallLeftParticipantHandler';
import {
    VideoCallMeetingCreatedHandler
} from './app/shared/services/socketActionHandlers/VideoCallMeetingCreatedHandler';
import { UISocketService } from './app/shared/services/ui.socket.service';
import { SharedModule } from './app/shared/shared.module';
import { UserEnvApi } from './app/shared/store/api/user-env';
import loginReducer, { LOGIN_INITIAL_STATE } from './app/shared/store/reducers/login';
import userEnvReducer, { USER_ENV_INITIAL_STATE } from './app/shared/store/reducers/user-env';
import { SiteAssignationService } from './app/site-assignation/services/site-assignation.service';
import { SiteAssignationApi } from './app/site-assignation/store/api/site-assignation-api';
import { SiteAssignationEffects } from './app/site-assignation/store/effects/site-assignation';
import siteAssignationReducer, {
    SITE_ASSIGNATION_INITIAL_STATE,
} from './app/site-assignation/store/reducers/site-assignation';
import { HttpBoxGateway } from './app/site-detail/components/device-detail/HttpBoxGateway';
import { HttpDeviceGateway } from './app/site-detail/components/device-detail/HttpDeviceGateway';
import { HttpUiProfileGateway } from './app/site-detail/components/device-detail/HttpUiProfileGateway';
import { HttpSetupGateway } from './app/site-detail/components/gateways-selector/HttpSetupGateway';
import { DeviceService } from './app/site-detail/services/device.service';
import { SiteService } from './app/site-detail/services/site.service';
import { DevicesAPI } from './app/site-detail/store/api/devices';
import { SiteDetailApi } from './app/site-detail/store/api/site';
import { AnalyticsEffects } from './app/site-detail/store/effects/analytics';
import { BoxDetailEffects } from './app/site-detail/store/effects/box-detail';
import { DeviceDetailEffects } from './app/site-detail/store/effects/device-detail';
import { GatewaysEffects } from './app/site-detail/store/effects/gateways';
import { InterventionEffects } from './app/site-detail/store/effects/intervention';
import { SiteDetailNavigationEffect } from './app/site-detail/store/effects/navigation';
import { NotificationsEffects } from './app/site-detail/store/effects/notifications';
import { RouterEffects } from './app/site-detail/store/effects/router';
import { SiteEffects } from './app/site-detail/store/effects/site';
import { TasksEffects } from './app/site-detail/store/effects/tasks';
import deviceDetailReducer, { DEVICE_DETAIL_INITIAL_STATE } from './app/site-detail/store/reducers/device-detail';
import devicesReducer, { DEVICES_INITIAL_STATE } from './app/site-detail/store/reducers/devices';
import gatewayReducer, { GATEWAYS_INITIAL_STATE } from './app/site-detail/store/reducers/gateways';
import uiReducer, { UI_INITIAL_STATE } from './app/site-detail/store/reducers/ui';
import { HttpFavoriteSitesGateway } from './app/site-favorite/services/http-favorite-sites-gateway.service';
import { FavoriteSitesEffects } from './app/site-favorite/store/effects/favorite-site';
import favoriteSitesReducer, { FAVORITE_SITES_INITIAL_STATE } from './app/site-favorite/store/reducers/favorite-site';
import { HttpSitesGateway } from './app/site-list/services/http-sites-gateway.service';
import { SitesEffects } from './app/site-list/store/effects/site-list';
import SitesReducer, { SITES_INITIAL_STATE } from './app/site-list/store/reducers/sites';
import { DailyVideoCallProvider } from './app/video-conference/services/DailyVideoCallProvider';
import { HttpVideoCallGateway } from './app/video-conference/services/HttpVideoCallGateway';
import { ResendVideoCallInvitationUseCase } from './app/video-conference/services/ResendVideoCallInvitationUseCase';
import { StartVideoCallUseCase } from './app/video-conference/services/StartVideoCallUseCase';
import { StopVideoCallUseCase } from './app/video-conference/services/StopVideoCallUseCase';
import { VideoCallParticipantManager } from './app/video-conference/services/VideoCallParticipantManager';
import { VideoCallAnalyticsEffects } from './app/video-conference/store/effects/analytics';
import { UIVideoCallEffects } from './app/video-conference/store/effects/ui-video-call';
import { VideoCallEffects } from './app/video-conference/store/effects/video-call';
import uiVideoCallsReducer, { UI_VIDEO_CALLS_INITIAL_STATE } from './app/video-conference/store/reducers/ui-video-call';
import videoCallsReducer, { VIDEO_CALLS_INITIAL_STATE } from './app/video-conference/store/reducers/video-call';
import { detectBrowser } from './detectBrowser';
import { environment } from './environments/environment';
import { initializeTagCommander } from './initializeTagCommander';
import { initSentry } from './initSentry';

function bootstrapApplication() {
    fetch(`/config/config.json?v=${p.version}`)
        .then((response) => response.json())
        .then((config) => {
            detectBrowser();
            initSentry(config);

            if (environment.production) {
                initializeTagCommander(config);
                enableProdMode();
            }

            bootstrapApplication_1(AppComponent, {
                providers: [
                    importProvidersFrom(
                        BrowserModule,
                        FormsModule,
                        ReactiveFormsModule,
                        MatDialogModule,
                        MatExpansionModule,
                        MatGridListModule,
                        routing,
                        TranslateModule.forRoot({
                            loader: {
                                provide: TranslateLoader,
                                useFactory: createTranslateLoader,
                                deps: [HttpClient],
                            },
                        }),
                        EffectsModule.forRoot([]),
                        EffectsModule.forFeature([
                            AlertNotificationAnalyticsEffects,
                            AnalyticsEffects,
                            AlertResolutionEffects,
                            AlertResolutionNavigationEffects,
                            AlertsNotificationConfigEffects,
                            AlertsMailingConfigEffects,
                            AlertsEffects,
                            BoxDetailEffects,
                            DeviceDetailEffects,
                            FavoriteSitesEffects,
                            GatewaysEffects,
                            InterventionEffects,
                            LangEffects,
                            MaintenancePeriodsEffects,
                            NotificationsEffects,
                            RouterEffects,
                            SiteAssignationEffects,
                            SiteEffects,
                            SitesEffects,
                            SiteDetailNavigationEffect,
                            TasksEffects,
                            VideoCallEffects,
                            UIVideoCallEffects,
                            VideoCallAnalyticsEffects,
                        ]),
                        StoreModule.forRoot({
                            router: routerReducer,
                        }),
                        StoreDevtoolsModule.instrument({
                            maxAge: 25,
                            trace: true,
                            traceLimit: 40,
                            logOnly: environment.production,
                        }),
                        StoreModule.forFeature('user-env', userEnvReducer, {
                            initialState: USER_ENV_INITIAL_STATE,
                        }),
                        StoreModule.forFeature('ui-state', uiReducer, {
                            initialState: UI_INITIAL_STATE,
                        }),
                        StoreModule.forFeature('interventions', interventionsReducer, {
                            initialState: INTERVENTIONS_INITIAL_STATE,
                        }),
                        StoreModule.forFeature('site-favorite', favoriteSitesReducer, {
                            initialState: FAVORITE_SITES_INITIAL_STATE,
                        }),
                        StoreModule.forFeature('sites', SitesReducer, {
                            initialState: SITES_INITIAL_STATE,
                        }),
                        StoreModule.forFeature('site-assignation', siteAssignationReducer, {
                            initialState: SITE_ASSIGNATION_INITIAL_STATE,
                        }),
                        StoreModule.forFeature('login', loginReducer, {
                            initialState: LOGIN_INITIAL_STATE,
                        }),
                        StoreModule.forFeature('legaldoc', legalDocReducer, {
                            initialState: LEGAL_DOCUMENT_INITIAL_STATE,
                        }),
                        StoreModule.forFeature('alerts', alertsReducer, {
                            initialState: ALERTS_INITIAL_STATE,
                        }),
                        StoreModule.forFeature('admin', adminReducer, {
                            initialState: ADMIN_INITIAL_STATE,
                        }),
                        StoreModule.forFeature('messages', messageReducer, {
                            initialState: MESSAGES_INITIAL_STATE,
                        }),
                        StoreModule.forFeature('devices', devicesReducer, {
                            initialState: DEVICES_INITIAL_STATE,
                        }),
                        StoreModule.forFeature('device-detail', deviceDetailReducer, {
                            initialState: DEVICE_DETAIL_INITIAL_STATE,
                        }),
                        StoreModule.forFeature('admin-maintenance-periods', adminMaintenancePeriodReducer, {
                            initialState: ADMIN_MAINTENANCE_PERIOD_INITIAL_STATE,
                        }),
                        StoreModule.forFeature('maintenance-period', maintenancePeriodReducer, {
                            initialState: MAINTENANCE_PERIOD_INITIAL_STATE,
                        }),
                        StoreModule.forFeature('gateways', gatewayReducer, {
                            initialState: GATEWAYS_INITIAL_STATE,
                        }),
                        StoreModule.forFeature('alert-resolution', alertResolutionReducer, {
                            initialState: ALERT_RESOLUTION_INITIAL_STATE,
                        }),
                        StoreModule.forFeature('alerts-notification', alertsNotificationReducer, {
                            initialState: ALERTS_NOTIFICATION_INITIAL_STATE,
                        }),
                        StoreModule.forFeature('video-calls', videoCallsReducer, {
                            initialState: VIDEO_CALLS_INITIAL_STATE,
                        }),
                        StoreModule.forFeature('ui-video-calls', uiVideoCallsReducer, {
                            initialState: UI_VIDEO_CALLS_INITIAL_STATE,
                        }),
                        SharedModule,
                        MatBadgeModule,
                        MatSelectModule,
                        NgxMaskDirective,
                        ScrollingModule,
                        MatTooltipModule,
                        MatDialogModule,
                        StoreRouterConnectingModule.forRoot(),
                    ),
                    provideHttpClient(withInterceptorsFromDi()),
                    provideAnimations(),
                    provideCharts(withDefaultRegisterables()),
                    provideNgxMask(),
                    {
                        provide: ErrorHandler,
                        useValue: Sentry.createErrorHandler({
                            showDialog: false, //true,
                        }),
                    },
                    {
                        provide: Sentry.TraceService,
                        deps: [Router],
                    },
                    {
                        provide: APP_INITIALIZER,
                        useFactory: () => () => {
                        },
                        deps: [Sentry.TraceService],
                        multi: true,
                    },
                    { provide: APP_CONFIG, useValue: config },
                    AnalyticsService,
                    SecurityService,
                    SiteService,
                    HttpFavoriteSitesGateway,
                    HttpSitesGateway,
                    UserService,
                    DeviceService,
                    QueryService,
                    QueryHttpService,
                    AlertService,
                    AlertsNotificationConfigService,
                    InterventionService,
                    MessageService,
                    ModalService,
                    UISocketService,
                    AuthGuard,
                    AuthService,
                    AdminAuthGuard,
                    RoleGuardService,
                    BoxDiagService,
                    CountriesService,
                    HttpService,
                    AdminAuthService,
                    AdminHttpService,
                    BackOfficeService,
                    AdminMaintenancePeriodService,
                    AdminSecurityService,
                    AdminSharedService,
                    NavigationService,
                    UserEnvApi,
                    SiteDetailApi,
                    InterventionsApi,
                    SiteAssignationApi,
                    AdminApi,
                    AdminMaintenancePeriodApi,
                    DebugApi,
                    LocalStorageService,
                    MessagesApi,
                    MaintenancePeriodService,
                    {
                        provide: WINDOW_OBJECT,
                        useValue: window,
                    },
                    {
                        provide: WEBSOCKET,
                        useFactory: (config: AppConfig) => io(config.servegoApi.url, { transports: ['websocket'] }),
                        deps: [APP_CONFIG],
                    },
                    ErrorService,
                    LegalDocsApi,
                    UserLegalDocService,
                    DevicesAPI,
                    SiteAssignationService,
                    DailyVideoCallProvider,
                    VideoCallParticipantManager,
                    StartVideoCallUseCase,
                    StopVideoCallUseCase,
                    ResendVideoCallInvitationUseCase,
                    { provide: 'AlertResolutionGateway', useClass: HttpAlertResolutionGateway },
                    { provide: 'SiteGateway', useClass: SiteService },
                    { provide: 'FavoriteSitesGateway', useClass: HttpFavoriteSitesGateway },
                    { provide: 'SitesGateway', useClass: HttpSitesGateway },
                    { provide: 'DeviceGateway', useClass: HttpDeviceGateway },
                    { provide: 'UiProfileGateway', useClass: HttpUiProfileGateway },
                    { provide: 'BoxGateway', useClass: HttpBoxGateway },
                    { provide: 'SetupGateway', useClass: HttpSetupGateway },
                    { provide: 'AlertNotificationConfigGateway', useClass: HttpAlertNotificationConfigGateway },
                    { provide: 'AlertMailingConfigGateway', useClass: HttpAlertMailingConfigGateway },
                    { provide: 'VideoCallGateway', useClass: HttpVideoCallGateway },
                    { provide: 'VideoCallProvider', useClass: DailyVideoCallProvider },
                    { provide: 'ValueStorage', useClass: LocalStorageService },
                    { provide: socketActionHandlersToken, useClass: DeviceCreatedHandler, multi: true },
                    { provide: socketActionHandlersToken, useClass: DeviceLabelUpdatedHandler, multi: true },
                    { provide: socketActionHandlersToken, useClass: DeviceRemovedHandler, multi: true },
                    { provide: socketActionHandlersToken, useClass: DeviceStateUpdatedHandler, multi: true },
                    { provide: socketActionHandlersToken, useClass: ExecutionStatusUpdatedHandler, multi: true },
                    { provide: socketActionHandlersToken, useClass: InterventionGrantedHandler, multi: true },
                    { provide: socketActionHandlersToken, useClass: NewInstallerMessageHandler, multi: true },
                    { provide: socketActionHandlersToken, useClass: ServegoAccessGrantedHandler, multi: true },
                    { provide: socketActionHandlersToken, useClass: VideoCallInvitationSentHandler, multi: true },
                    { provide: socketActionHandlersToken, useClass: VideoCallJoinedParticipantHandler, multi: true },
                    { provide: socketActionHandlersToken, useClass: VideoCallLeftHandler, multi: true },
                    { provide: socketActionHandlersToken, useClass: VideoCallLeftParticipantHandler, multi: true },
                    { provide: socketActionHandlersToken, useClass: VideoCallMeetingCreatedHandler, multi: true },
                ],
            }).catch((err) => console.error(err));
        });
}

bootstrapApplication();

/* eslint-enable */
