import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { VIDEO_CALL_STATUS } from '../../../video-conference/models/video-call';
import { VIDEO_CALL_EVENT_TYPE } from '../../../video-conference/models/video-call-event';
import { VideoCallsActions } from '../../../video-conference/store/actions/videoCall';
import { UISocketDataMessage } from '../../models/UISocket';
import { VideoCallEventBase } from '../../models/VideoCallEventBase';
import { AppState } from '../../store/app-state';
import { SocketAction, SocketActionHandler } from '../SocketActionHandler';

interface UISocketLeftParticipantVideoCallEvent extends VideoCallEventBase {
    type: VIDEO_CALL_EVENT_TYPE.LEFT_PARTICIPANT;
    userName: string;
    sessionId: string;
    isOwner: boolean;
}

export interface UISocketLeftParticipantVideoCallEventMessage extends UISocketDataMessage {
    videoCallEvent: UISocketLeftParticipantVideoCallEvent;
}

@Injectable()
export class VideoCallLeftParticipantHandler implements SocketActionHandler {
    readonly action: SocketAction = 'left_participant';

    constructor(private store: Store<AppState>) {}

    handle({ videoCallEvent: event }: UISocketLeftParticipantVideoCallEventMessage): void {
        this.store.dispatch(
            VideoCallsActions.videoCallStatusUpdated({
                siteId: event.siteId,
                status: event.isOwner ? VIDEO_CALL_STATUS.AVAILABLE : VIDEO_CALL_STATUS.IN_PROGRESS,
            }),
        );
    }
}
