import { InjectionToken } from '@angular/core';
import { UISocketDataMessage } from '../models/UISocket';

export type SocketAction = string;

export const socketActionHandlersToken = new InjectionToken<SocketActionHandler[]>('SocketActionHandlers');

export interface SocketActionHandler {
    readonly action: SocketAction;

    handle(data: UISocketDataMessage): void;
}
